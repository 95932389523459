<template>
  <header>
    <section class="wrap">
      <router-link :to="{ name: 'Home' }"
        ><img class="img" src="img/logo.png" alt="Capital Intellect Holdings"
      /></router-link>

      <nav class="navigation">
        <div class="nav">
          <router-link
            exact
            active-class="active"
            class="item"
            :to="{ name: 'Home' }"
            >What we do</router-link
          >

          <router-link active-class="active" class="item" :to="{ name: 'Team' }"
            >Who we are</router-link
          >
        </div>
      </nav>
      <div class="contact-us">
        <a href="mailto:newcontact@capintellect.com" class="btn">Contact US</a>
      </div>

      <img
        @click="isMenuOpened = !isMenuOpened"
        class="burger-button"
        src="/img/burger.png"
        alt="menu button"
      />
    </section>
    <nav :class="{ opened: isMenuOpened }" class="nav-hidden">
      <router-link
        @click.native="isMenuOpened = false"
        exact
        active-class="active"
        class="item"
        :to="{ name: 'Home' }"
        >What we do</router-link
      >

      <router-link
        @click.native="isMenuOpened = false"
        active-class="active"
        class="item"
        :to="{ name: 'Team' }"
        >Who we are</router-link
      >
    </nav>
  </header>
</template>

<script>
export default {
  name: "TheHeader",
  data() {
    return {
      isMenuOpened: false,
    };
  },
};
</script>

<style lang="scss" scoped>
header {
  position: relative;
}

.wrap {
  padding: 23px 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 767px) {
    padding: 20px 30px;
  }
}

.img {
  display: block;
  width: 260px;
}

.nav {
  display: flex;

  @media screen and (max-width: 767px) {
    display: none;
  }

  .item {
    color: #222222;
    display: block;
    padding: 9px 20px;
    opacity: 0.6;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.9;
    }

    &.active {
      opacity: 1;
      color: #1a1b1f;
      font-weight: 600;

      &:hover {
        opacity: 0.8;
        color: #32343a;
      }
    }
  }
}

.contact-us {
  max-width: 260px;
  width: 100%;
  text-align: right;

  @media screen and (max-width: 767px) {
    display: none;
  }

  .btn {
    padding: 12px 25px;
    border-radius: 0px;
    background-color: #1a1b1f;
    transition: background-color 400ms ease, opacity 400ms ease,
      color 400ms ease;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      background-color: #32343a;
    }
  }
}

.burger-button {
  display: none;
  width: 22px;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    display: block;
  }
}

.nav-hidden {
  display: none;
  position: absolute;
  bottom: -90px;
  width: 100%;

  &.opened {
    display: block;
  }

  .item {
    display: block;
    padding: 15px 30px;
    transition: background-color 400ms ease, opacity 400ms ease,
      color 400ms ease;
    text-align: center;
    color: #1a1b1f;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
      color: #32343a;
      background-color: #f7f8f9;
    }
  }
}
</style>
