<template>
  <div id="app">
    <TheHeader />
    <div class="view">
      <router-view />
    </div>
    <TheFooter />
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader";
import TheFooter from "@/components/TheFooter";
export default {
  name: "App",
  components: { TheHeader, TheFooter },
};
</script>

<style lang="scss">
body {
  min-height: 100%;
  background-color: #fff;
  font-family: Montserrat, sans-serif;
  color: #1a1b1f;
  font-size: 16px;
  margin: 0;
  line-height: 28px;
  font-weight: 400;
}

.view {
  min-height: calc(100vh - 300px);
}
</style>
