<template>
  <footer>
    <section class="container">
      <router-link :to="{ name: 'Home' }">
        <img
          class="img"
          src="img/logo-white.png"
          alt="Capital Intellect Holdings"
      /></router-link>

      <nav class="nav">
        <router-link
          exact
          active-class="active"
          class="item"
          :to="{ name: 'Home' }"
          >What we do</router-link
        >

        <router-link active-class="active" class="item" :to="{ name: 'Team' }"
          >Who we are</router-link
        >
      </nav>
    </section>
    <div class="copyright">
      © {{ currentYear }} Capital Intellect Holdings LLC
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
footer {
  padding-top: 60px;
  padding-bottom: 40px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #000;
  text-align: center;
}

.container {
  background-color: #000;
  margin: 0 auto;
  margin: auto;
  max-width: 940px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.img {
  height: 60px;

  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.nav {
  a {
    display: block;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 5px;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.8;
    }
  }
}

.copyright {
  padding: 20px;
  color: #fff;
  margin-top: 20px;

  @media screen and (max-width: 767px) {
    text-align: start;
  }
}
</style>
